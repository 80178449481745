<template>
    <st-modal ref='modal'
              hide-header-delimiter
              no-close-on-backdrop
              no-close-on-esc
              hide-footer
              hideable
              hide-header-close
              size="md"
              :title="$t('APPLICATION.SEND_REVIEW_MODAL.TITLE')"
              :hideHeaderClose="loadingGeneration"
              customClass="form-modal"
              :id="modalId"
    >
        <template #body>
            <form class="form"
                  novalidate="novalidate"
                  id="st_modal_send_review_form"
            >
                <div class="row pb-6">
                    <div class="col">
                        <div class="form-group">
                            <label>
                                {{ fields.citizen_review.label }}
                            </label>
                            <b-form-textarea
                                :ref="fields.citizen_review.name"
                                :name="fields.citizen_review.name"
                                v-model="model[fields.citizen_review.name]"
                                :disabled="loadingGeneration"
                                rows="10"
                            />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <st-button
                        size="large"
                        variant="secondary"
                        :callback="hide"
                        :disabled="loadingGeneration"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                        :spinner="loadingGeneration"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SEND') }}</span>
                    </st-button>
                </div>
            </form>
        </template>
    </st-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { createFormValidation } from '@/shared/utils/create-form-validation';

const { fields } =  ApplicationModel;

const formSchema = new FormSchema([
   fields.citizen_review,
]);

export default {
    name: 'ApplicationSendReviewModal',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            modalId: `st-modal-send-review-${generateRandomId()}`,
            loadingGeneration: false,
            polling: null,
        }
    },
    methods: {
        ...mapActions({
            doSendReview: 'applications/form/sendReview',
            doCheckGenerationStatus: 'applications/form/checkGenerationStatus',
        }),
        show() {
            this.model = formSchema.initialValues({});
            this.$refs['modal'].show();
        },
        hide() {
            this.clearPolling();
            this.$refs['modal'].hide();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                this.loadingGeneration = true;
                const payload = {
                    id: this.applicationId,
                    citizen_review: this.model.citizen_review,
                }
                const response = await this.doSendReview(payload)
                switch(response) {
                    case 'error':
                        this.loadingGeneration = false;
                        break;
                    case 'initiated':
                        this.polling = setInterval(() => {
                            this.checkDocumentStatus(this.applicationId);
                        }, 1000);
                        break;
                }
            }
        },
        async checkDocumentStatus(id) {
            const status = await this.doCheckGenerationStatus(id);

            try {
                if (status !== 'initiated') {
                    this.loadingGeneration = false;
                    clearInterval(this.polling);
                }

                if (status === 'completed') {
                    await this.handleDocumentCompleted();
                }

                if (status === 'error') {
                    await this.handleDocumentError();
                }
            } catch (error) {
                this.confirmationStatus = 'error';
                this.loadingGeneration = false;
                clearInterval(this.polling);
                await this.handleDocumentError();
            }
        },
        clearPolling() {
            this.polling && clearInterval(this.polling);
        },
        handleDocumentError() {
            this.hide();
            this.$emit('documentError');
        },
        handleDocumentCompleted() {
            this.hide();
            this.$emit('documentCompleted');
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['applications/sendReview'];
        }
    },
    async created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.fv = createFormValidation(this.modalId, this.rules);
            }
        });
    },
    destroy() {
        this.clearPolling();
    }
}
</script>
